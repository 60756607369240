import { TooltipIconButton } from './TooltipIconButton';
import ShareIcon from '@mui/icons-material/Share';

export const ShareButton = ({ onClick }) => {
  return (
    <TooltipIconButton onClick={onClick} tooltipTitle="Share">
      <ShareIcon />
    </TooltipIconButton>
  );
};
