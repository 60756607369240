import { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilteredEnumList extends Component {
  static propTypes = {
    enum: PropTypes.array.isRequired,
    selected: PropTypes.array,
  };

  render() {
    if (this.props.selected === null) {
      return <ul className="filtered-enum-list" />;
    }

    const filtered = this.props.enum.filter((s) => {
      return this.props.selected.includes(s.value);
    });

    return (
      <ul className="filtered-enum-list">
        {filtered.map(function (e) {
          return <li key={e.value}>{e.label}</li>;
        })}
      </ul>
    );
  }
}
