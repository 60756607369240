import styled from 'styled-components';

import { Box } from '@mui/material';
import { TemplateQuickActionsProps } from './types';
import { TooltipIconButton } from 'sharedComponents/Buttons/TooltipIconButton';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

const TemplateQuickActions: React.FC<TemplateQuickActionsProps> = ({
  handleDelete,
  handleEditClick,
  handleOpenModalClick,
  template,
}) => {
  return (
    <ActionsContainer>
      <TooltipIconButton
        tooltipTitle="Create job from template"
        onClick={(event) => handleOpenModalClick(event, template, true)}
      >
        <ControlPointDuplicateIcon sx={{ color: '#909090' }} />
      </TooltipIconButton>
      <TooltipIconButton tooltipTitle="Delete" onClick={(event) => handleDelete(event)}>
        <CancelIcon sx={{ color: '#909090' }} />
      </TooltipIconButton>
      <TooltipIconButton tooltipTitle="Edit" onClick={handleEditClick}>
        <EditIcon sx={{ color: '#909090' }} />
      </TooltipIconButton>
    </ActionsContainer>
  );
};

export default TemplateQuickActions;

const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});
