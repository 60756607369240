import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AltModal as Modal } from 'ui-kit';
import { AutoTagsModalProps } from './types';
import { QuestionType } from 'types/types';
import CheckboxVariant from './CheckboxVariant';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import YesNoVariant from './YesNoVariant';

const AutoTagsModal: React.FC<AutoTagsModalProps> = ({
  onClose,
  questionType,
  show,
  existingAutoTags,
  fetchedTags,
  onSave,
}) => {
  const isYesNoQuestion = questionType === QuestionType.yes_no;
  const isStatementCheckbox = questionType === QuestionType.statementCheckbox;
  const [yesOptionValue, setYesOptionValue] = useState(() => {
    if (!existingAutoTags) {
      return null;
    }

    const value = existingAutoTags.find((tag) => tag.option === 'Yes');
    if (!value) return null;
    return value.tag_id;
  });

  const [noOptionValue, setNoOptionValue] = useState(() => {
    if (!existingAutoTags) {
      return null;
    }

    const value = existingAutoTags.find((tag) => tag.option === 'No');
    if (!value) return null;
    return value.tag_id;
  });

  const [agreeOptionValue, setAgreeOptionValue] = useState(() => {
    if (!existingAutoTags) {
      return null;
    }

    const value = existingAutoTags.find((tag) => tag.option === 'Agree');
    if (!value) return null;
    return value.tag_id;
  });

  const [disagreeOptionValue, setDisagreeOptionValue] = useState(() => {
    if (!existingAutoTags) {
      return null;
    }

    const value = existingAutoTags.find((tag) => tag.option === 'Disagree');
    if (!value) return null;
    return value.tag_id;
  });

  const handleSetYesOptionValue = (id) => setYesOptionValue(id);
  const handleSetNoOptionValue = (id) => setNoOptionValue(id);
  const handleSetAgreeOptionValue = (id) => setAgreeOptionValue(id);
  const handleSetDisagreeOptionValue = (id) => setDisagreeOptionValue(id);

  const handleSave = () => {
    const yesNumber = yesOptionValue === null ? null : Number(yesOptionValue);
    const noNumber = noOptionValue === null ? null : Number(noOptionValue);
    const agreeNumber = agreeOptionValue === null ? null : Number(agreeOptionValue);
    const disagreeNumber = disagreeOptionValue === null ? null : Number(disagreeOptionValue);

    onSave({
      Yes: yesNumber,
      No: noNumber,
      Agree: agreeNumber,
      Disagree: disagreeNumber,
    });

    onClose();
  };

  return (
    <Modal.AltModal isOpen={show} onClose={onClose}>
      <Modal.Title>Automatic Tags</Modal.Title>
      <Body>
        <AssignmentText>
          Assign tags based on a candidate&apos;s response to this question.
        </AssignmentText>

        {isYesNoQuestion && (
          <YesNoVariant
            fetchedTags={fetchedTags}
            handleSetNoOptionValue={handleSetNoOptionValue}
            handleSetYesOptionValue={handleSetYesOptionValue}
            yesOptionValue={yesOptionValue}
            noOptionValue={noOptionValue}
          />
        )}
        {isStatementCheckbox && (
          <CheckboxVariant
            fetchedTags={fetchedTags}
            handleSetAgreeOptionValue={handleSetAgreeOptionValue}
            handleSetDisagreeOptionValue={handleSetDisagreeOptionValue}
            agreeOptionValue={agreeOptionValue}
            disagreeOptionValue={disagreeOptionValue}
          />
        )}

        <Subtext>
          Changes will not be applied to candidates who&apos;ve already submitted applications.
        </Subtext>

        <Subtext>
          You can create a new tag from the{' '}
          <SettingsLink to="/district/settings">settings page.</SettingsLink>
        </Subtext>
      </Body>
      <Modal.Actions>
        <ModalFooterNew
          cancel={onClose}
          save={handleSave}
          saveButtonLabel="Save"
          saveDisabled={false}
        />
      </Modal.Actions>
    </Modal.AltModal>
  );
};

export default AutoTagsModal;

const AssignmentText = styled('div')({
  paddingBottom: '24px',
});

const Body = styled(Modal.Body)({
  fontSize: '16px',
  padding: '27px 27px',
});

const SettingsLink = styled(Link)({
  color: ' #00A37D',
  paddingTop: '24px',
});

const Subtext = styled('div')({
  color: '#666',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 400,
  paddingBottom: '4px',
});
