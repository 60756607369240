import { IconButton as MuiIconButton } from '@mui/material';
import { Tooltip } from 'sharedComponents/Tooltip';
import { TooltipIconButtonProps } from './types';

export const TooltipIconButton: React.FC<TooltipIconButtonProps> = ({
  children,
  sx,
  tooltipTitle,
  onClick,
  placement,
}) => {
  return (
    <Tooltip title={tooltipTitle} placement={placement}>
      <MuiIconButton aria-label={tooltipTitle} sx={sx} onClick={onClick}>
        {children}
      </MuiIconButton>
    </Tooltip>
  );
};
