import { getGradesArray, getJobUrl, getSalaryRange, gradesWithGroups, renderFTE } from '../utils';
import { InfoBlock, Left, Right, Row } from '../styles';
import { JobInformationProps } from '../types';
import { Tooltip } from 'sharedComponents/Tooltip';
import auth from 'utils/auth';
import FilteredEnumList from 'utils/filteredenumlist';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Moment from 'moment';
import { roleStatusByValue } from 'utils/enums';
import { useLocation } from 'react-router';

export const JobInformation: React.FC<JobInformationProps> = ({
  job,
  isDistrictUser,
  showJobViewField,
  subcategories,
}) => {
  const location = useLocation();
  const showLinkToATS =
    !auth.isCandidateUser() && auth.inPostingOnlyDistrict() && job.link_to_ats !== '';
  const isInternalATSUser =
    (auth.isDistrictUser() || auth.isSchoolUser()) && !auth.inPostingOnlyDistrict();
  const displayDeadlineText = job.deadline
    ? `${Moment(job.deadline).format('l')} at 8PM Pacific Time`
    : 'Until filled';

  const getStatuses = () => {
    return job.statuses_available.map(
      (status, index) => `${status.label}${index !== job.statuses_available.length - 1 ? '; ' : ''}`
    );
  };

  const getJobboardURL = () => {
    if (!job.jobboards || auth.inPostingOnlyDistrict()) return null;
    const colon = window.location.hostname.indexOf('localhost') !== -1 ? ':' : '';
    const jobUrl = `${window.location.protocol}//${window.location.hostname}${colon}${window.location.port}/jobs/${job.jobboards[0]?.slug}`;

    return <a href={jobUrl}>{jobUrl}</a>;
  };

  const getDistrictTitle = () => {
    let districtTitleShowing = job.district.name;

    const isDistrictUrl = location.pathname.includes('district');
    const isSchoolUrl = location.pathname.includes('school');
    const shouldShowInternalTitle = isDistrictUrl || isSchoolUrl;

    if (job.jobboards && job.jobboards.length) {
      if (job.jobboards[0].internal_title && shouldShowInternalTitle) {
        districtTitleShowing = job.jobboards[0].internal_title;
      } else {
        districtTitleShowing = job.jobboards[0].title;
      }
    }

    return districtTitleShowing;
  };

  const renderSchools = (job) => {
    if (!job.schoolroles || !job.schoolroles.length) {
      return null;
    }

    const schoolsWithUnfilledVacancy = job.schoolroles.filter(
      (school) => school.has_unfilled_vacancies
    );

    if (schoolsWithUnfilledVacancy.length) {
      return (
        <Row>
          <Left sx={{ display: 'flex' }}>
            Schools &nbsp;
            {isInternalATSUser && (
              <Tooltip
                title="There may be additional locations tagged on this job. However, candidates will only
see locations with unfilled vacancies."
              >
                <HelpOutlineIcon />
              </Tooltip>
            )}
          </Left>
          <Right>
            <ul className="filtered-enum-list" data-testid="schools">
              {schoolsWithUnfilledVacancy.map((sr) => {
                return <li key={sr.id}>{sr.school.name}</li>;
              })}
            </ul>
          </Right>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const showPublicLink = () => {
    if (
      (!isDistrictUser && !job.district_role) ||
      job.status === roleStatusByValue.submitted ||
      auth.inPostingOnlyDistrict()
    )
      return null;

    const districtRoleId = isDistrictUser ? job.id : job.district_role.id;
    const jobUrl = getJobUrl(districtRoleId);

    return (
      <Row>
        <Left>Public link for sharing</Left>
        <Right data-testid="public-link">{<a href={jobUrl}>{jobUrl}</a>}</Right>
      </Row>
    );
  };

  const startDate = job.start_date ? Moment(job.start_date).format('MMM YYYY') : null;

  return (
    <InfoBlock>
      {showPublicLink()}
      {showLinkToATS && (
        <Row>
          <Left> {auth.inPostingOnlyDistrict() ? 'Where to apply' : 'Link to ATS'}</Left>
          <Right>
            <a data-testid="external-where-to-apply" href={job.link_to_ats}>
              {job.link_to_ats}
            </a>
          </Right>
        </Row>
      )}
      <Row>
        <Left>Organization</Left>
        <Right data-testid="organization-name">{getDistrictTitle()}</Right>
      </Row>

      {job.contact && (
        <Row>
          <Left>Contact for Questions</Left>
          <Right data-testid="contact">{job.contact}</Right>
        </Row>
      )}
      {renderSchools(job)}
      {showJobViewField('subjects', false) && (
        <Row>
          <Left>Subjects</Left>
          <Right>
            <FilteredEnumList enum={subcategories} selected={job.subjects} />
          </Right>
        </Row>
      )}
      {showJobViewField('grades', false) && (
        <Row>
          <Left>Grades</Left>
          <Right>
            <FilteredEnumList enum={gradesWithGroups()} selected={getGradesArray(job)} />
          </Right>
        </Row>
      )}
      {showJobViewField('salary_max', false) && (
        <Row>
          <Left>Salary</Left>
          <Right data-testid="salary">{getSalaryRange(job)}</Right>
        </Row>
      )}
      {showJobViewField('fulltime', false) && (
        <Row>
          <Left>Employment Type</Left>
          <Right data-testid="employment-type">{renderFTE(job.fulltime)}</Right>
        </Row>
      )}
      {showJobViewField('statuses_available', false) && (
        <Row>
          <Left>Statuses</Left>
          <Right data-testid="statuses">{getStatuses()}</Right>
        </Row>
      )}
      {showJobViewField('scorecards', false) && (
        <Row>
          <Left>Scorecards</Left>
          <Right data-testid="scorecards">
            {job.scorecards?.map((scorecard) => scorecard.title).join('; ')}
          </Right>
        </Row>
      )}
      {showJobViewField('deadline', false) && (
        <Row>
          <Left>Application deadline</Left>
          <Right data-testid="deadline">{displayDeadlineText}</Right>
        </Row>
      )}
      {showJobViewField('start_date', false) && (
        <Row>
          <Left>Start date</Left>
          <Right data-testid="start-date">{startDate}</Right>
        </Row>
      )}
      {showJobViewField('jobboards', true) && (
        <Row>
          <Left>Posted to</Left>
          <Right data-testid="jobboard-link">{getJobboardURL()}</Right>
        </Row>
      )}
    </InfoBlock>
  );
};
