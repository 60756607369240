import connectSubscriptionsAPI from 'api/connectSubscriptionsAPI';
import { ConnectAlertsBannerDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateCode } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { useConnectActions } from 'features/Connect/features/ExpressInterest/utils';
import {
  connectRedirectUrls,
  connectUrlParams,
} from 'features/Connect/features/JobDiscovery/constants';
import { buildUrl } from 'utils/util';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationBanner } from 'sharedComponents/Banner';
import auth from 'utils/auth';

export function ConnectAlertsBanner(): React.ReactElement {
  const stateCode = useStateCode();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const history = useHistory();
  const user = auth.getUser();
  const { handleConnectUserAction } = useConnectActions();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (user) {
        const subscriptions = await connectSubscriptionsAPI.getSubscriptions();
        if (subscriptions.length > 0) {
          setIsSubscribed(true);
        }
      }
    };

    fetchSubscriptions();
  }, [user]);

  const profileInfoCompleted = () =>
    user?.first_name?.length > 1 &&
    user?.last_name?.length > 1 &&
    user?.preference.open_to_connect_opportunities;

  const redirectToAlertsFlow = () => {
    const urlParamsToAdd = {
      [connectUrlParams.REDIRECT]: stateCode,
      [connectUrlParams.ACTION]: ConnectGatedActions.ALERT,
    };
    if (!user) {
      history.push(buildUrl(connectRedirectUrls.SIGN_UP, urlParamsToAdd));
    } else {
      if (profileInfoCompleted()) {
        history.push(buildUrl(connectRedirectUrls.PREFERENCES, urlParamsToAdd));
      } else {
        history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
      }
    }
  };

  const redirectToAlertsFlowCallbackFunction = async () => {
    await redirectToAlertsFlow();
  };

  return (
    <>
      {isSubscribed ? (
        <NotificationBanner
          dataTestId={ConnectAlertsBannerDataTestIds.SUBSCRIBED_BANNER}
          label="Successfully subscribed to job alerts!"
          clickable={false}
        />
      ) : (
        <NotificationBanner
          dataTestId={ConnectAlertsBannerDataTestIds.NOT_SUBSCRIBED_BANNER}
          label={'Subscribe to job alerts for Missouri teaching jobs \xa0\u2192'}
          clickable={true}
          onClickFunction={() =>
            handleConnectUserAction(
              user,
              stateCode,
              ConnectGatedActions.ALERT,
              redirectToAlertsFlowCallbackFunction
            )
          }
        />
      )}
    </>
  );
}
