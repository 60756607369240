import { useEffect, useState } from 'react';
import { FilterOptions } from './types';
import {
  distanceFilterOptions,
  gradeFilterOptions,
  gradePreferencesOptions,
  subjectFilterOptions,
  subjectPreferencesOptions,
  connectSubjectFilterOptionsV2,
} from 'features/Connect/features/JobDiscovery/constants';
import { useStateCode } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants/connect';

interface FilterAndLoadingOptions extends FilterOptions {
  isLoadingFilterOptions: boolean;
}

export const useConnectFilterOptions = (): FilterAndLoadingOptions => {
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const path = window.location.pathname;
  const isUserPreferences = path.includes('/preferences');

  const stateCode = useStateCode();

  useEffect(() => {
    // For now we're only using the new subject filter options for Tennessee.
    // This unblocks us from merging and we can test the new options
    if (CONNECT_JOBBOARD_STATES.TN.toLowerCase() === stateCode.toLowerCase()) {
      setSubjectOptions(connectSubjectFilterOptionsV2);
    } else {
      setSubjectOptions(isUserPreferences ? subjectPreferencesOptions : subjectFilterOptions);
    }

    setDistanceOptions(distanceFilterOptions);
    setGradeOptions(isUserPreferences ? gradePreferencesOptions : gradeFilterOptions);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    subjectOptions,
    distanceOptions,
    gradeOptions,
    isLoadingFilterOptions: isLoading,
  };
};
