import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { Actions, School } from 'types/connectTypes';
import { SavedSchoolButtonProps } from '../types';

import UserSavedSchoolAPI from 'features/Connect/api/userSavedSchoolAPI';
import auth from 'utils/auth';
import {
  checkUserProfileCompletion,
  useConnectActions,
} from 'features/Connect/features/ExpressInterest/utils';
import { ConnectActionButtonsDataTestIds } from 'data-testids/ConnectDataTestIds';
import {
  connectRedirectUrls,
  connectUrlParams,
} from 'features/Connect/features/JobDiscovery/constants';
import { useStateCode } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { buildUrl } from 'utils/util';

export const SaveSchoolButton: React.FC<SavedSchoolButtonProps> = ({
  school,
  actions,
  sx,
  size,
}) => {
  const stateCode = useStateCode();
  const history = useHistory();
  const user = auth.getUser();
  const { handleConnectUserAction } = useConnectActions();

  const [isSchoolSavedForUser, setIsSchoolSavedForUser] = useState(school?.isSavedByUser || false);

  useEffect(() => {
    setIsSchoolSavedForUser(school?.isSavedByUser || false);
  }, [school.isSavedByUser]);

  const toggleSaveSchoolOnClickHandler = async (school: School, actions: Actions) => {
    if (!user) {
      const urlParamsToAdd = {
        [connectUrlParams.REDIRECT]: stateCode,
        [connectUrlParams.SCHOOL]: school.nces_id,
        [connectUrlParams.ACTION]: ConnectGatedActions.SAVE_SCHOOL,
      };
      history.push(buildUrl(connectRedirectUrls.SIGN_UP, urlParamsToAdd));
      return;
    }

    if (!user?.preference?.nimble_connect_profile?.is_completed) {
      checkUserProfileCompletion(user, history, stateCode, school, ConnectGatedActions.SAVE_SCHOOL);
    }

    const newSavedState = !isSchoolSavedForUser;
    setIsSchoolSavedForUser(newSavedState);

    if (newSavedState) {
      try {
        const response = await UserSavedSchoolAPI.saveSchool(school.nces_id);
        if (response.status === 201) {
          actions.toggleSavedSchoolIds(school.nces_id);
          school.isSavedByUser = true;
          setIsSchoolSavedForUser(true);
        } else {
          setIsSchoolSavedForUser(false);
          school.isSavedByUser = false;
        }
      } catch (error) {
        setIsSchoolSavedForUser(false);
        school.isSavedByUser = false;
      }
    } else {
      try {
        const response = await UserSavedSchoolAPI.unsaveSchool(school.nces_id);
        if (response.status === 204) {
          actions.toggleSavedSchoolIds(school.nces_id);
          school.isSavedByUser = false;
          setIsSchoolSavedForUser(false);
        } else {
          setIsSchoolSavedForUser(true);
          school.isSavedByUser = true;
        }
      } catch (error) {
        setIsSchoolSavedForUser(true);
        school.isSavedByUser = true;
      }
    }
  };

  const toggleSaveSchoolCallbackFunction = async () =>
    await toggleSaveSchoolOnClickHandler(school, actions);

  const handleSaveSchoolClick = async () => {
    await handleConnectUserAction(
      user,
      stateCode,
      ConnectGatedActions.SAVE_SCHOOL,
      toggleSaveSchoolCallbackFunction,
      school
    );
  };

  return (
    <SecondaryButton
      onClick={handleSaveSchoolClick}
      size={size}
      dataTestId={ConnectActionButtonsDataTestIds.SAVE_SCHOOL_BUTTON}
      sx={{
        borderRadius: '8px',
        borderColor: '#BDBDBD',
        color: isSchoolSavedForUser ? '#CB3768' : '#999999',
        minWidth: '44px',
        padding: '12px',
        ...sx,
      }}
    >
      <ToolbarItem>{isSchoolSavedForUser ? <Favorite /> : <FavoriteBorder />}</ToolbarItem>
    </SecondaryButton>
  );
};

const ToolbarItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 12,
}));
