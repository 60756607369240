import axios from 'axios';
import { JobviewJob, JobViewQuestion, JobViewSubcategory } from 'components/JobView/types';
import { gradesWithGroups } from 'components/JobView/utils';
import { JobStatus } from 'sharedComponents/Chip/types';
import auth from 'utils/auth';
import { EMPLOYMENT_TYPE } from 'utils/constants';
import { gradeMapping, roleStatusByValue, roleStatusIdsByName, salarytype } from 'utils/enums';
import { makeAllUrlsValid } from 'utils/util';

export const shouldShowInternalNotesField = (job: JobviewJob): boolean => {
  const isDistrictAdmin = auth.isDistrictAdmin();
  const currentUser = auth.getUser();

  if (!isDistrictAdmin) {
    if (
      job.status === roleStatusByValue.submitted ||
      (job.updated_by === currentUser.id && job.status === roleStatusByValue.draft)
    ) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const shouldShowJobViewField = (job: JobviewJob, fieldName: keyof JobviewJob): boolean => {
  const field = job[fieldName];
  const isSchoolAdmin = auth.isSchoolAdmin();
  const isDistrictUser = auth.isDistrictUser();
  const fieldsToNotShowCandidate = new Set(['jobboards', 'internal_role_notes']);
  const isFieldNotToShowCandidate = fieldsToNotShowCandidate.has(fieldName);

  // we only send salary_max to this function
  // but we should show the salary field if there is either a salary min _or_ a salary max
  if (fieldName === 'salary_max') {
    return !!job['salary_max'] || !!job['salary_min'];
  }

  if (!field || (Array.isArray(field) && field.length === 0)) {
    return false;
  }

  if (
    fieldName === 'contact' ||
    fieldName === 'description' ||
    fieldName === 'benefits' ||
    fieldName === 'questions' ||
    fieldName === 'custom_questions' ||
    fieldName === 'school_preferences_question' ||
    fieldName === 'deadline'
  ) {
    return true;
  }

  const schoolsWithUnfilledVacancy = job.schoolroles?.filter(
    (school) => school.has_unfilled_vacancies
  );

  const usersWhoCanSeeScorecards =
    auth.isDistrictAdmin() || auth.isSuperAdminUser() || !auth.inPostingOnlyDistrict();
  const usersWhoCanSeeStatuses = auth.isDistrictUser() || auth.isSchoolUser();

  const showATSLink =
    !auth.isCandidateUser() && auth.inPostingOnlyDistrict() && job.link_to_ats !== '';

  if (fieldName === 'internal_role_notes' && !shouldShowInternalNotesField(job)) {
    return false;
  } else if (fieldName === 'requiredapplicationattachment_set' && job.district.posting_only) {
    return false;
  } else if (fieldName === 'statuses_available' && !usersWhoCanSeeStatuses) {
    return false;
  } else if (!isSchoolAdmin && !isDistrictUser && isFieldNotToShowCandidate) {
    return false;
  } else if (fieldName === 'jobboards' && auth.inPostingOnlyDistrict()) {
    return false;
  } else if (fieldName === 'jobboards' && job.status === roleStatusIdsByName.pending) {
    return false;
  } else if (fieldName === 'scorecards' && !usersWhoCanSeeScorecards) {
    return false;
  } else if (fieldName === 'link_to_ats' && !showATSLink) {
    return false;
  } else if (fieldName === 'schoolroles' && schoolsWithUnfilledVacancy.length === 0) {
    return false;
  }

  return true;
};

export const shouldShowAdditionalQuestions = (
  job: JobviewJob,
  sortedQuestions: JobViewQuestion[]
): boolean => {
  return (
    (shouldShowJobViewField(job, 'questions') ||
      (shouldShowJobViewField(job, 'custom_questions') &&
        sortedQuestions?.filter((question) => question.included_in_preview).length > 0) ||
      shouldShowJobViewField(job, 'school_preferences_question')) &&
    !job.district.posting_only
  );
};

export const shouldSeePublicLink = (job: JobviewJob): boolean => {
  const userIsNotDistrictUser = !auth.isDistrictUser();
  const jobIsNotDistrictJob = !job.district_role;
  const jobIsPending = job.status === roleStatusByValue.submitted;
  const userIsInPostingOnly = auth.inPostingOnlyDistrict();

  if ((userIsNotDistrictUser && jobIsNotDistrictJob) || jobIsPending || userIsInPostingOnly) {
    return false;
  }

  return true;
};

export const getUserTypeById = {
  0: 'Candidate',
  10: 'School user',
  20: 'School admin',
  30: 'District user',
  40: 'District admin',
  50: 'Super admin',
};

export const getJobViewV2Url = (id: number): string => {
  const colon = window.location.hostname.indexOf('localhost') !== -1 ? ':' : '';
  return `${window.location.protocol}//${window.location.hostname}${colon}${window.location.port}/jobview/${id}`;
};

export const getHTML = (job: JobviewJob, field: keyof JobviewJob): null | { __html: string } => {
  if (job[field] === null) {
    return null;
  }

  return { __html: makeAllUrlsValid(job[field]) };
};

export const extractTextFromHTML = (description: string): string => {
  const container = document.createElement('div');
  container.innerHTML = description;

  const textContent = container.textContent || '';

  const words = textContent.split(/\s+/).slice(0, 150).join(' ');
  return words;
};

export const getSalaryRange = (job: JobviewJob): string => {
  const salaryMin = job.salary_min
    ? job.salary_min.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '';

  const salaryMax = job.salary_max
    ? job.salary_max.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '';

  const salaryObj = salarytype().find((st) => {
    return st.value === job.salary_type;
  });

  const salaryType = salaryObj && (salaryMin || salaryMax) ? salaryObj.label : '';
  const salaryDash = salaryMin && salaryMax ? ' - ' : '';

  if (!salaryMin && salaryMax) {
    return `Up to ${salaryMax} ${salaryType}`;
  } else if (!salaryMax && salaryMin) {
    return `Starting at ${salaryMin} ${salaryType}`;
  } else {
    return `${salaryMin}${salaryDash}${salaryMax} ${salaryType}`;
  }
};

export const getGradesArray = (job: JobviewJob): number[] => {
  const jobGradesArray = job.grades.slice();
  const elementarySchool = gradeMapping().elementary.every((e) => jobGradesArray.includes(e));
  const middleSchool = gradeMapping().middle_school.every((e) => jobGradesArray.includes(e));
  const highSchool = gradeMapping().high_school.every((e) => jobGradesArray.includes(e));

  if (elementarySchool) {
    gradeMapping().elementary.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(14);
  }
  if (middleSchool) {
    gradeMapping().middle_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(15);
  }
  if (highSchool) {
    gradeMapping().high_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(16);
  }

  return jobGradesArray;
};

export const getSchoolsForJobViewV2 = (job: JobviewJob): string => {
  const schoolsWithUnfilledVacancy = job.schoolroles?.filter(
    (school) => school.has_unfilled_vacancies
  );
  return schoolsWithUnfilledVacancy?.map(({ school }) => school.name).join(', ');
};

export const getGradesForJobViewV2 = (job: JobviewJob): string => {
  const selectedGrades = getGradesArray(job);

  const filteredGrades = gradesWithGroups()
    ? gradesWithGroups().filter((selected) => selectedGrades.includes(selected.value))
    : selectedGrades; // can this be tested?
  return filteredGrades.map((item) => item.label).join(', ');
};

export const getSubcategories = (district_id: number): Promise<JobViewSubcategory[]> => {
  return axios.get(`/api/categories/`, { params: { district_id } }).then((r) => {
    const categories = r.data || [];

    return (
      categories.length > 0 &&
      categories
        ?.map((category) => {
          return category.subcategories.map((subcategory) => {
            return {
              value: subcategory.id,
              label: subcategory.label,
            };
          });
        })
        .flat()
    );
  });
};

export const getSubjectsForJobViewV2 = (
  job: JobviewJob,
  subcategories: JobViewSubcategory[]
): string => {
  const filteredSubjects = subcategories.filter((s) => {
    return job.subjects?.includes(s.value);
  });

  return filteredSubjects.map((item) => item.label).join(', ');
};

export const getJobboardURL = (job: JobviewJob): string => {
  if (!job.jobboards || auth.inPostingOnlyDistrict()) return null;
  const colon = window.location.hostname.indexOf('localhost') !== -1 ? ':' : '';
  return `${window.location.protocol}//${window.location.hostname}${colon}${window.location.port}/jobs/${job.jobboards[0]?.slug}`;
};

export const getEmploymentTypeText = (fulltime: number): string => {
  switch (fulltime) {
    case EMPLOYMENT_TYPE.partTime:
      return 'Part time';
    case EMPLOYMENT_TYPE.fullAndPartTime:
      return 'Full and part time';
    case EMPLOYMENT_TYPE.fullTime:
      return 'Full time';
    default:
      return 'Full time';
  }
};

export const truncationCharacterLimit = 100;

export const truncateText = (items: string, shouldReadMore: boolean): string => {
  const shouldShowEllipsis = items.length > truncationCharacterLimit ? '\u2026' : '';
  return shouldReadMore ? items : items.slice(0, truncationCharacterLimit) + shouldShowEllipsis;
};

export const initialNumberOfStatusesToShow = 4;

export const shouldShowActivityFeed = (
  job_status: number,
  isAtSchoolOrDistrictRoute: boolean
): boolean => {
  const jobHasActivityFeed =
    job_status === roleStatusByValue.draft ||
    job_status === roleStatusByValue.active ||
    job_status === roleStatusByValue.archived;

  return (
    auth.isDistrictAdmin() &&
    auth.hasJobActivityFeed() &&
    jobHasActivityFeed &&
    isAtSchoolOrDistrictRoute
  );
};

export const shouldShowBackToJobsLink = (isAtValidRoute: boolean): boolean => {
  const isAdminUser = auth.isAdminUser(auth.getUser());
  return isAtValidRoute && isAdminUser;
};

export const shouldShowStatusToggle = (jobStatus: JobStatus, pathname: string): boolean => {
  if (!pathname.includes('/district/jobview')) return false;

  const hasFeatureFlag = auth.hasJobClosureNotifications();
  const jobIsActive = jobStatus === roleStatusByValue.active;
  const userCanToggleStatus = auth.isDistrictAdmin();

  return hasFeatureFlag && jobIsActive && userCanToggleStatus;
};
