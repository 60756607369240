import { Box, styled } from '@mui/material';

export const SectionContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
  lineHeight: '20px',
  marginBottom: '28px',
  padding: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width: 747px)': {
    marginBottom: '8px',
  },
});

export const Block = styled(SectionContainer)({
  flexDirection: 'column',
});

export const CTAContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

export const InfoBlock = styled(SectionContainer)({
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Left = styled(Box)({
  fontWeight: 600,
  flex: '1 1',
});

export const JobViewContainer = styled(Box)({
  padding: '28px',
  display: 'block',
  maxWidth: '1200px',
  width: '100%',
  '@media screen and (max-width: 747px)': {
    padding: '8px',
  },
});

export const Right = styled(Box)({
  flex: '2 1',
});

export const Row = styled(Box)({
  display: 'flex',
  margin: '5px 0',
  '@media screen and (max-width: 747px)': {
    flexDirection: 'column',
  },
});

export const TextAndStatus = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
