import { Component } from 'react';

import axios from 'axios';
import FeatureContext from 'context/featureContext';
import { JobViewV2 } from 'features/JobViewV2/JobViewV2';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import customScorecardListAPI from '../api/customScorecardListAPI';
import JobView from '../components/JobView';
import LoadingSpinner from '../components/loadingSpinner';
import auth from '../utils/auth';
import { roleStatuses } from '../utils/enums';
import { showWarning } from '../utils/message';

const statuses = _.keyBy(roleStatuses(), 'value');

function getRoleStatus() {
  // District users can only put jobs back to 'pending approval'
  if (auth.isDistrictUserOnly()) {
    return statuses.submitted.key;
  } else {
    return statuses.active.key;
  }
}

class JobPreviewContainer extends Component {
  static contextType = FeatureContext;
  state = {
    job: null,
    waitingOnSubmission: false,
  };

  redirectToJobsList(searchParams = '') {
    const destination = auth.isDistrictUserOnly() ? 'pending' : 'active_open%2Cactive_closed';
    this.props.history.push(`/district/jobslist?f_JS=${destination}${searchParams}`);
  }

  componentDidMount() {
    document.body.classList.add('job-view-page');
    this.fetchJob();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchJob();
    }
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('job-view-page');
  }

  fetchJob() {
    var url = '/api/role/';
    // Add a query string for anonymous jobview viewers
    let queryString = '';
    if (
      window.location.href.indexOf('district') === -1 &&
      window.location.href.indexOf('school') === -1
    ) {
      queryString = '?jobview=true';
    }

    if (auth.isSchoolUser()) {
      url = '/api/schoolrole/';
    }
    axios.get(`${url}${this.props.match.params.id}/${queryString}`).then((r) => {
      customScorecardListAPI.fetchCustomScorecardsByRole(r.data.id).then((scorecards) => {
        if (!this.ignoreLastFetch) {
          let job = r.data;
          job.scorecards = scorecards;
          job.schoolroles = job.schoolroles.filter((sr) => sr.automatic_routing === true);
          this.setState({ job });
        }
      });
    });
  }

  onSubmit = async () => {
    if (!this.state.waitingOnSubmission) {
      this.setState({ waitingOnSubmission: true });
      const putJob = { ...this.state.job };
      const status = getRoleStatus();

      putJob.status = status;
      if (status === statuses.submitted.key) {
        putJob.submitted_by = auth.getUser().id;
      }
      await axios.put(`/api/role/${putJob.id}/`, putJob);
      this.updateSchoolroleStatuses(putJob);

      if (window.location.search.indexOf('created=1') > -1) {
        this.redirectToJobsList(`&j=${putJob.id}`);
      }
      this.redirectToJobsList();
    }
  };

  updateSchoolroleStatuses = async (districtRole) => {
    /** update schoolrole statuses */
    const updatedSchoolroles = districtRole.schoolroles.map((schoolrole) => ({
      ...schoolrole,
      status: districtRole.status,
    }));

    if (updatedSchoolroles.length > 0) {
      try {
        await axios.post('/api/schoolrole/bulk_update/', updatedSchoolroles);
      } catch (err) {
        console.error(err);
        showWarning(
          `Oops, there was a problem posting this job.
          Please contact support@hirenimble.com for assistance.`,
          7000
        );
      }
    }
  };

  render() {
    if (this.state.waitingOnSubmission) {
      return <LoadingSpinner />;
    }
    const shouldShowJobViewV2 = auth.hasJobViewV2();

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {!shouldShowJobViewV2 && this.state.job && (
          <JobView job={this.state.job} preview={true} onSubmit={this.onSubmit} />
        )}
        {shouldShowJobViewV2 && this.state.job && (
          <JobViewV2 job={this.state.job} isPreview={true} onSubmit={this.onSubmit} />
        )}
      </div>
    );
  }
}

export default withRouter(JobPreviewContainer);

const TabbedPostingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: 100%;
    align-items: start;
    justify-content: start;
  }
`;
