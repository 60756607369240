import axios, { AxiosResponse, Canceler } from 'axios';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import {
  JobDiscoveryActiveFilters,
  JobDiscoveryFiltersInterface,
  School,
} from 'types/connectTypes';

import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import UserSavedSchoolAPI from 'features/Connect/api/userSavedSchoolAPI';

import auth from 'utils/auth';

interface MarketplaceSchoolsHook {
  error: Error | false;
  results: School[];
  count: number;
  hasMore: boolean;
  isFetchingNextPage: boolean;
  setIsFetchingNextPage: (boolean) => void;
}

interface JobDiscoverySearchParamsInterface {
  state_abbreviation?: string;
  page?: number;
  query?: string;
  subcategories?: string[];
  grades?: string[];
  distance_radius_miles?: string;
  location_latitude?: string;
  location_longitude?: string;
  district_uuid?: string[];
  school_uuid?: string[];
}

export const useConnectDiscovery = (
  {
    state_abbreviation,
    page,
    query,
    location,
    subcategories,
    grades,
    school_uuid,
    district_uuid,
  }: JobDiscoveryFiltersInterface,
  activeFilters: JobDiscoveryActiveFilters,
  setIsLoading: (boolean) => void,
  setIsLoadingInitialFilterResults: (boolean) => void,
  useDebounce: boolean
): MarketplaceSchoolsHook => {
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [error, setError] = useState<Error | false>(false);
  const [count, setCount] = useState(0);
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const searchParams: JobDiscoverySearchParamsInterface = {
    state_abbreviation,
    page,
    query,
    distance_radius_miles: location.distance_radius_miles,
    location_latitude: location.location_latitude,
    location_longitude: location.location_longitude,
    subcategories,
    grades,
    school_uuid,
    district_uuid,
  };

  // If a filter is not active, remove it from searchParams.
  // This handles the case where a filter has all items selected.
  // In that case, the filter is considered inactive and we don't
  // want to exclude any results.

  for (const [key, value] of Object.entries(searchParams)) {
    if (value === undefined || value === '' || activeFilters[key] === false) {
      delete searchParams[key];
    }
  }

  const usp = new URLSearchParams(searchParams as Record<string, string>);

  // Build a stable queryString and re-fetch when it changes
  usp.sort();

  const queryString = '?' + usp.toString();

  async function updateSchoolsWithExpressInterestAndSave(schools: School[]) {
    if (!auth.loggedIn()) {
      setResults(
        schools.map((school) => ({
          ...school,
          hasExpressedInterest: false,
          isSavedByUser: false,
        }))
      );
      return;
    }

    try {
      const [contactedSchools, savedSchools] = await Promise.all([
        ExpressInterestAPI.getSchoolsUserExpressedInterestIn(),
        UserSavedSchoolAPI.getSchoolsUserHasSaved(),
      ]);
      setResults(
        schools.map((school) => ({
          ...school,
          hasExpressedInterest: contactedSchools.some(
            (contactedSchool) => contactedSchool.school_nces_id === school.nces_id
          ),
          isSavedByUser: savedSchools.some(
            (savedSchools) => savedSchools.school_nces_id === school.nces_id
          ),
        }))
      );
    } catch (error) {
      setResults(
        schools.map((school) => ({
          ...school,
          hasExpressedInterest: false,
          isSavedByUser: false,
        }))
      );
    }
  }

  const getMarketplaceSchools = (
    searchParams: JobDiscoverySearchParamsInterface,
    isLoadingMoreResults = false
  ): Canceler | void => {
    let cancel: Canceler | undefined;
    // When only a few schools or orgs are deselected from the filter, the url becomes too long.
    // In most cases the server returns a 414 HTTP error, and all is well.  Of course
    // Heroku had to be different, so they just ignore this request at their router and
    // it never reaches our django server.
    setIsLoading(true);
    if (
      (searchParams.school_uuid?.length ?? 0) > 100 ||
      (searchParams.district_uuid?.length ?? 0) > 100
    ) {
      const e = new Error('URL Too Long') as Error & {
        response?: { status: number };
        filterName?: string;
      };
      e.response = { status: 414 };
      e.filterName =
        (searchParams.school_uuid?.length ?? 0 > 100) ? 'school_uuid' : 'district_uuid';
      setError(e);
      cancel = () => {}; // no-op
    } else {
      setError(false);
      axios({
        method: 'GET',
        url: '/api/marketplace/schools/',
        params: searchParams,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then(async (response: AxiosResponse) => {
          const { data } = response;
          const schools = isLoadingMoreResults ? [...results, ...data.items] : data.items;
          await updateSchoolsWithExpressInterestAndSave(schools);
          setCount(data.total);
          setHasMore(data.page < data.pages);
          setIsLoading(false);
          setIsFetchingNextPage(false);
          setIsLoadingInitialFilterResults(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(e);
        });
    }
    return () => cancel?.();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMarketplaceSchoolsDebounced = useCallback(
    debounce(
      (searchParams, isFetchingNextPage) => getMarketplaceSchools(searchParams, isFetchingNextPage),
      2000
    ),
    []
  );

  useEffect(() => {
    if (useDebounce) {
      getMarketplaceSchoolsDebounced(searchParams, isFetchingNextPage);
    } else {
      getMarketplaceSchools(searchParams, isFetchingNextPage);
    }
    // eslint-disable-next-line
  }, [queryString]);

  return {
    error,
    results,
    count,
    hasMore,
    isFetchingNextPage,
    setIsFetchingNextPage,
  };
};
