import { Component, createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { FetchedAuthUser } from 'containers/types';
import { JobAdditionalDetails } from './components/JobAdditionalDetails';
import { JobInformation } from './components/JobInformation';
import { JobViewContainer } from './styles';
import { JobViewHeader } from './components/JobViewHeader';
import { JobViewProps, JobViewState } from './types';
import { setSubcategories } from '../../utils/statusviewutils';
import auth from '../../utils/auth';
import FeatureContext from 'context/featureContext';
import ShareToSocialMedia from 'components/ShareToSocialMedia';
import { shouldShowActivityFeed } from 'features/JobViewV2/utils';

class JobView extends Component<JobViewProps & RouteComponentProps, JobViewState> {
  currentUser: FetchedAuthUser;
  internalNoteRef: RefObject<HTMLDivElement>;
  isDistrictAdmin: boolean;
  isDistrictUser: boolean;
  isSchoolUser: boolean;
  onInternalPage: boolean;
  setSubcategories: (id: number) => void;
  static contextType = FeatureContext;

  constructor(props) {
    super(props);
    this.internalNoteRef = createRef();
    this.state = {
      openShareModal: false,
      roleToShare: props.job,
      subcategories: [],
    };
    this.currentUser = auth.getUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.isDistrictUser = auth.isDistrictUser();
    this.isSchoolUser = auth.isSchoolUser();
    this.setSubcategories = setSubcategories.bind(this);

    // this component is shared between the internal jobview page and the external jobview page, but
    // requirements are different, so we need a way to distinguish. It's also possible to view the
    // external jobview page as an admin, so we have to also check for /district/ and /school/.
    this.onInternalPage =
      this.currentUser?.profile.user_type > 0 &&
      (window.location.pathname.indexOf('/district/') !== -1 ||
        window.location.pathname.indexOf('/school/') !== -1);
  }

  componentDidMount() {
    this.setSubcategories(this.props.job.district.id);
    if (window.location.hash === '#internal_notes') {
      this.scrollToNoteTag();
    }
  }

  showInternalNotesField = () => {
    if (!this.isDistrictAdmin) {
      if (
        this.props.job.status === 10 ||
        (this.props.job.updated_by === this.currentUser.id && this.props.job.status === 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  scrollToNoteTag = () => this.internalNoteRef.current.scrollIntoView();

  showJobViewField = (fieldName, dontShowToCandidate) => {
    const field = this.props.job[fieldName];

    if (!field || (Array.isArray(field) && field.length === 0)) {
      return false;
    }

    const usersWhoCanSeeScorecards =
      auth.isDistrictAdmin() || auth.isSuperAdminUser() || !auth.inPostingOnlyDistrict();
    const usersWhoCanSeeStatuses = auth.isDistrictUser() || auth.isSchoolUser();

    if (fieldName === 'internal_role_notes' && !this.showInternalNotesField) {
      return false;
    } else if (
      fieldName === 'requiredapplicationattachment_set' &&
      this.props.job.district.posting_only
    ) {
      return false;
    } else if (
      fieldName === 'salary_max' &&
      (!this.props.job.salary_min || !this.props.job.salary_max)
    ) {
      return false;
    } else if (fieldName === 'statuses_available' && !usersWhoCanSeeStatuses) {
      return false;
    } else if (!this.isSchoolUser && !this.isDistrictUser && dontShowToCandidate) {
      return false;
    } else if (fieldName === 'jobboards' && auth.inPostingOnlyDistrict()) {
      return false;
    } else if (fieldName === 'scorecards' && !usersWhoCanSeeScorecards) {
      return false;
    }

    return true;
  };

  openShareModal = () => this.setState({ openShareModal: true });

  render() {
    const { job } = this.props;
    const showInternalInfo = this.onInternalPage && job.internal_requirements_specified;

    const isAtSchoolOrDistrictRoute =
      location?.pathname.includes('/school/') || location?.pathname.includes('/district/');

    return (
      <JobViewContainer>
        <JobViewHeader
          isDistrictAdmin={this.isDistrictAdmin}
          isInternalCandidate={this.props.isInternalCandidate}
          isPreview={this.props.preview}
          isSchoolUser={this.isSchoolUser}
          job={job}
          onSubmit={this.props.onSubmit}
          openShareModal={this.openShareModal}
          userDistrict={this.currentUser?.profile.district?.id}
        />
        <JobInformation
          isDistrictUser={this.isDistrictUser}
          job={this.props.job}
          showJobViewField={this.showJobViewField}
          subcategories={this.state.subcategories}
        />
        <JobAdditionalDetails
          internalNoteRef={this.internalNoteRef}
          isInternalCandidate={this.props.isInternalCandidate}
          job={this.props.job}
          onInternalPage={this.onInternalPage}
          showJobViewField={this.showJobViewField}
          showInternalInfo={showInternalInfo}
          shouldShowActivityFeed={shouldShowActivityFeed(job.status, isAtSchoolOrDistrictRoute)}
        />
        <ShareToSocialMedia
          isOpen={this.state.openShareModal}
          onClose={() => {
            this.setState({ openShareModal: false });
          }}
          roleToShare={this.state.roleToShare}
        />
      </JobViewContainer>
    );
  }
}

export default withRouter(JobView);
