import { Block } from '../styles';
import { Box, styled, Typography } from '@mui/material';
import { JobViewBlockProps } from '../types';

export const JobViewBlock: React.FC<JobViewBlockProps> = ({ children, header, title }) => {
  return (
    <Block>
      {title && <BlockTitle data-testid="jobview-block-title">{title}</BlockTitle>}
      {header && <Box>{header}</Box>}
      <Box>{children}</Box>
    </Block>
  );
};

export const BlockTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '24px',
  marginBottom: '14px',
});
