import { createContext, useContext, ReactNode } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { connectUrlParams } from './JobDiscovery/constants';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

const StateCodeContext = createContext<string | undefined>(undefined);

const StateCodeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { search } = useLocation();
  const match = useRouteMatch<{ id: string }>('/connect/:id');
  const rawStateCode = match?.params.id;
  const stateCodeId = rawStateCode;
  const searchParams = new URLSearchParams(search);
  const stateCodeParam = searchParams.get(connectUrlParams.REDIRECT);
  const stateCode = (stateCodeParam || stateCodeId || CONNECT_JOBBOARD_STATES.MO).toUpperCase();

  return <StateCodeContext.Provider value={stateCode}>{children}</StateCodeContext.Provider>;
};

export const useStateCode = () => {
  const context = useContext(StateCodeContext);
  if (context === undefined) {
    throw new Error('useStateCode must be used within a StateCodeProvider');
  }
  return context;
};

export { StateCodeProvider };
