import { useHistory } from 'react-router';

import { ApplyButton, CTAContainer } from './styles';
import { JobViewV2TitleButtonsProps } from '../types';
import { nimbleTheme } from 'theme';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { activeStatusByValue, roleStatusByValue } from 'utils/enums';
import auth from 'utils/auth';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ATSJobViewDataTestIds } from '../../../data-testids/ATS';

export const JobViewV2TitleButtons: React.FC<JobViewV2TitleButtonsProps> = ({
  handleOpenShareModal,
  isPreview,
  isInternalCandidate,
  job,
  onSubmit,
  userDistrict,
}) => {
  const history = useHistory();
  const jobDistrict = job?.district?.id;
  const shouldShowEditButton = jobDistrict === userDistrict;
  const isPostingOnly = job?.district.posting_only;
  const isDistrictAdminOrJobIsPending =
    auth.isDistrictAdmin() || job?.status === roleStatusByValue.submitted;
  const backToJobEdit = () => history.push(`/district/jobedit/${job?.id}`);
  const isSchoolUser = auth.isSchoolUser();
  const showSocialMediaShareButton =
    job?.status === roleStatusByValue.active &&
    job?.active_status === activeStatusByValue.activeOpen &&
    !auth.inPostingOnlyDistrict();

  if (isPreview) {
    const postButtonText = auth.isDistrictUserOnly() ? 'Submit to Admin' : 'Post Job';

    return (
      <CTAContainer>
        <SecondaryButton
          dataTestId="back-to-edit-button"
          onClick={backToJobEdit}
          sx={{ marginRight: nimbleTheme.spacing(1) }}
          startIcon={<CreateOutlinedIcon />}
        >
          Back to Edit
        </SecondaryButton>
        <SecondaryButton
          dataTestId="print-button"
          onClick={() => window.print()}
          startIcon={<LocalPrintshopOutlinedIcon />}
          sx={{ marginRight: nimbleTheme.spacing(1) }}
        >
          Print
        </SecondaryButton>
        <PrimaryButton
          dataTestId={ATSJobViewDataTestIds.POST_BUTTON}
          onClick={onSubmit}
          startIcon={<SendIcon />}
        >
          {postButtonText}
        </PrimaryButton>
      </CTAContainer>
    );
  }

  if (isDistrictAdminOrJobIsPending) {
    return (
      <>
        {shouldShowEditButton && (
          <CTAContainer>
            {showSocialMediaShareButton && (
              <SecondaryButton
                dataTestId="share-button"
                onClick={() => handleOpenShareModal()}
                startIcon={<ShareOutlinedIcon />}
              >
                Share
              </SecondaryButton>
            )}
            <SecondaryButton
              dataTestId="print-button"
              onClick={() => window.print()}
              startIcon={<LocalPrintshopOutlinedIcon />}
            >
              Print
            </SecondaryButton>
            <PrimaryButton
              dataTestId="edit-button"
              onClick={backToJobEdit}
              startIcon={<CreateOutlinedIcon />}
            >
              Edit Posting
            </PrimaryButton>
          </CTAContainer>
        )}
      </>
    );
  } else if (isSchoolUser || auth.isDistrictUserOnly()) {
    return (
      <CTAContainer>
        {showSocialMediaShareButton && (
          <SecondaryButton
            dataTestId="share-button"
            onClick={() => handleOpenShareModal()}
            startIcon={<ShareOutlinedIcon />}
            sx={{ marginRight: nimbleTheme.spacing(1) }}
          >
            Share
          </SecondaryButton>
        )}
        <SecondaryButton
          dataTestId="print-button"
          onClick={() => window.print()}
          startIcon={<LocalPrintshopOutlinedIcon />}
        >
          Print
        </SecondaryButton>
      </CTAContainer>
    );
  } else {
    if (isPostingOnly) {
      const encodedJobTitleAndEmailSubject = encodeURIComponent('Application for: ' + job.title);
      let linkToApply: string = job.link_to_ats;

      linkToApply = linkToApply.includes('http')
        ? linkToApply
        : `mailto:${linkToApply}?subject=${encodedJobTitleAndEmailSubject}`;

      return (
        <a href={linkToApply.length > 0 ? linkToApply : ''} data-testid="apply-link">
          <ApplyButton dataTestId="apply-button">APPLY</ApplyButton>
        </a>
      );
    } else {
      const urlQuery = isInternalCandidate ? `?internal=${window.btoa('true')}` : '';
      const roleId =
        window.location.search.indexOf('is_school_role=1') > -1 ? job?.district_role : job?.id;

      return (
        <CTAContainer>
          <ApplyButton
            dataTestId={ATSJobViewDataTestIds.APPLY_BUTTON}
            onClick={() => history.push(`/applicant-profile/${roleId}/${urlQuery}`)}
            startIcon={<OpenInNewIcon />}
          >
            Apply
          </ApplyButton>
        </CTAContainer>
      );
    }
  }
};
