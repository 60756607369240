import Moment from 'moment';

import { roleStatusByValue } from 'utils/enums';
import auth from 'utils/auth';
import { getDeadline } from 'utils/roleutils';
import { Star, StarBorder } from '@mui/icons-material';
import { Tooltip } from 'sharedComponents/Tooltip';
import { calculateDerivedStatusProperties } from 'components/DistrictJobsList/DistrictJobRow/utils';
import { HideOnMobile, TextEllipsisContainer, TooltipText } from './styles';
import { JobRowContainer, StatusSection } from 'components/DistrictJobsList/DistrictJobRow/styles';
import { useHistory } from 'react-router';
import { JobQuickActions } from './JobQuickActions';
import axios from 'axios';
import { JobRowProps } from './types';
import { ATSJobsListDataTestIds } from '../../data-testids/ATS';

const SchoolsSection = ({ job, isSchoolUser }) => {
  if (isSchoolUser) {
    return (
      <TextEllipsisContainer data-testid={ATSJobsListDataTestIds.SCHOOL_NAME}>
        {job.school?.name ?? ''}
      </TextEllipsisContainer>
    );
  }

  let word = 'None';
  const roles = job.schoolroles.filter((sr) => sr.automatic_routing === true);

  if (roles.length === 1) {
    word = roles[0].school.name;
  } else if (roles.length > 1) {
    word = 'Multiple';
  }

  const getTooltipText = (item, index) =>
    index === roles.length - 1
      ? `${item.school.name} (${item.ftes_total})`
      : `${item.school.name} (${item.ftes_total}),`;

  if (word === 'Multiple' || word !== 'None') {
    return (
      <Tooltip
        placement="bottom"
        title={roles.map((item, index) => (
          <TooltipText key={item.name}>
            <ul>{getTooltipText(item, index)}</ul>
          </TooltipText>
        ))}
      >
        <TextEllipsisContainer>{word}</TextEllipsisContainer>
      </Tooltip>
    );
  } else {
    return <TextEllipsisContainer>{word}</TextEllipsisContainer>;
  }
};

export const JobRow: React.FC<JobRowProps> = ({
  archiveRole,
  closeRole,
  job,
  smallerLayout,
  toggleFeaturedRole,
  handleOpenModalClick,
  handleSetNetworkError,
  openMergeRoleModal,
  openReturnSubmittedRoleModal,
  openShareModal,
}) => {
  const history = useHistory();
  const isSchoolUser = auth.isSchoolAdmin();

  const renderDateAndSubtext = (date) => {
    if (!date) return null;

    return (
      <div>
        <div>{Moment(date).format('l')}</div>
        <div style={{ fontSize: '11px', color: 'rgb(187, 187, 187)' }}>
          {Moment(date).fromNow()}
        </div>
      </div>
    );
  };

  const duplicateRole = (roleId) => {
    axios
      .post(`/api/role/${roleId}/duplicate/`)
      .then((r) => {
        history.push(`/district/jobedit/${r.data.id}`);
      })
      .catch(() => handleSetNetworkError());
  };

  const renderDeadline = () => {
    if (job.status === roleStatusByValue.submitted) {
      return null;
    } else {
      const deadline = job.deadline;
      if (deadline !== null) {
        // deadlines are stored in UTC, but close (at 8PM PT / 11PM ET).
        // This assumes the dates are set in the continental US. We
        // might see some bugs if folks are setting deadlines
        // from timezones abroad
        const deadlineDate = getDeadline(deadline);

        return (
          <div>
            <div>{Moment(deadlineDate).format('l')}</div>
            <div style={{ fontSize: '11px', color: 'rgb(187, 187, 187)' }}>
              {Moment(deadlineDate).fromNow()}
            </div>
          </div>
        );
      } else {
        return 'Until Filled';
      }
    }
  };

  const renderDatePosted = () => {
    if (job.status === roleStatusByValue.submitted) {
      return null;
    } else {
      return renderDateAndSubtext(job.created);
    }
  };

  const renderStatusColumn = (statusColor, statusLabel) => (
    <>
      {job.status === roleStatusByValue.submitted && job.submitted_by ? (
        <Tooltip title={`Submitted by ${job.submitted_by}`}>
          <StatusSection statusColor={statusColor}>{statusLabel}</StatusSection>
        </Tooltip>
      ) : (
        <StatusSection statusColor={statusColor}>{statusLabel}</StatusSection>
      )}
    </>
  );

  const archive = (event) => {
    event.stopPropagation();
    archiveRole(job);
  };

  const close = (event) => {
    event.stopPropagation();
    closeRole(job);
  };

  const visitJob = (actionURL) => {
    const urlPrefix = isSchoolUser ? 'school' : 'district';
    history.push(`/${urlPrefix}/${actionURL}/${job.id}`);
  };

  const onFeaturedJobClick = (event) => {
    event.stopPropagation();
    toggleFeaturedRole(job);
  };

  const urlPrefix = isSchoolUser ? 'school' : 'district';

  const { statusColor, statusLabel, actionURL } = calculateDerivedStatusProperties(
    job.status,
    isSchoolUser,
    job.active_status
  );

  const showFeaturedJobs = auth.isDistrictAdmin();
  const isFeaturedJob = job.is_featured;
  const showSocialMediaShareButton =
    job.status === 20 && job.active_status === 1 && !auth.inPostingOnlyDistrict();

  const getTooltipText = () => {
    if (auth.inPostingOnlyDistrict()) {
      return 'Favorited jobs are shown at the top of your jobs list.';
    } else {
      return 'Favorited jobs are shown at the top of your jobs list and the external job board.';
    }
  };
  return (
    <div data-testid={ATSJobsListDataTestIds.ROW_CONTAINER}>
      <JobRowContainer onClick={() => visitJob(actionURL)} smallerLayout={smallerLayout}>
        {showFeaturedJobs && (
          <Tooltip onClick={onFeaturedJobClick} title={getTooltipText()} placement="bottom">
            {isFeaturedJob ? <Star color="primary" fontSize="medium" /> : <StarBorder />}
          </Tooltip>
        )}
        <Tooltip title={job.title} placement="bottom">
          <TextEllipsisContainer data-testid={ATSJobsListDataTestIds.ROW_TITLE_TEXT}>
            {job.title}
          </TextEllipsisContainer>
        </Tooltip>

        <div className={`${urlPrefix}job-row-status`}>
          {renderStatusColumn(statusColor, statusLabel)}
        </div>
        <HideOnMobile>
          <SchoolsSection job={job} isSchoolUser={isSchoolUser} />
        </HideOnMobile>
        <HideOnMobile>
          <div
            className={`${urlPrefix}job-row-date`}
            data-testid={ATSJobsListDataTestIds.ROW_DATE_TEXT}
          >
            {renderDeadline()}
          </div>
        </HideOnMobile>
        <HideOnMobile>
          <div
            className={`${urlPrefix}job-row-date`}
            data-testid={ATSJobsListDataTestIds.ROW_DATE_TEXT}
          >
            {renderDatePosted()}
          </div>
        </HideOnMobile>

        <HideOnMobile>
          <div className="quick-actions">
            <JobQuickActions
              archive={archive}
              close={close}
              duplicateRole={duplicateRole}
              handleOpenModalClick={(event) => {
                event.stopPropagation();
                handleOpenModalClick(job, true);
              }}
              job={job}
              openMergeRoleModal={openMergeRoleModal}
              openReturnSubmittedRoleModal={openReturnSubmittedRoleModal}
              openShareModal={openShareModal}
              showSocialMediaShareButton={showSocialMediaShareButton}
              visitJob={visitJob}
            />
          </div>
        </HideOnMobile>
      </JobRowContainer>
    </div>
  );
};
