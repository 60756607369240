import { Grade, Question } from 'types/types';
import { gradeMapping, grades, questionType, salarytype } from 'utils/enums';
import { JobviewJob } from './types';

export const getGradesArray = (job: JobviewJob): number[] => {
  const jobGradesArray = job.grades.slice();
  const elementarySchool = gradeMapping().elementary.every((e) => jobGradesArray.includes(e));
  const middleSchool = gradeMapping().middle_school.every((e) => jobGradesArray.includes(e));
  const highSchool = gradeMapping().high_school.every((e) => jobGradesArray.includes(e));

  if (elementarySchool) {
    gradeMapping().elementary.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(14);
  }
  if (middleSchool) {
    gradeMapping().middle_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(15);
  }
  if (highSchool) {
    gradeMapping().high_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(16);
  }
  return jobGradesArray;
};

export const getJobUrl = (id: number): string => {
  const colon = window.location.hostname.indexOf('localhost') !== -1 ? ':' : '';
  return `${window.location.protocol}//${window.location.hostname}${colon}${window.location.port}/jobview/${id}`;
};

export const getQuestionText = (question: Question): string => {
  switch (question.question_type) {
    case questionType.schoolPreferences:
      return 'Select school preferences';
    case questionType.open_response:
    case questionType.yes_no:
    case questionType.multiple_choice:
    case questionType.statementCheckbox:
    case questionType.direction_text:
      return question.prompt;
    case questionType.nimble:
    case questionType.text_question_model:
    default:
      return question.question;
  }
};

export const getSalaryRange = (job: JobviewJob): JSX.Element => {
  const salaryMin = job.salary_min
    ? job.salary_min.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '';

  const salaryMax = job.salary_max
    ? job.salary_max.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '';

  const salaryObj = salarytype().find((st) => {
    return st.value === job.salary_type;
  });
  const salaryType = salaryObj && (salaryMin || salaryMax) ? salaryObj.label : '';
  const salaryDash = salaryMin && salaryMax ? ' - ' : '';

  if (!salaryMin && salaryMax) {
    return <span>{`Up to ${salaryMax} ${salaryType}`}</span>;
  } else {
    return <span>{`${salaryMin}${salaryDash}${salaryMax} ${salaryType}`}</span>;
  }
};

export const gradesWithGroups = (): Grade[] => {
  const gradesToReturn = grades().slice();
  gradesToReturn.push({ value: 14, label: 'Elementary School', key: 'elementarySchool' });
  gradesToReturn.push({ value: 15, label: 'Middle School', key: 'middleSchool' });
  gradesToReturn.push({ value: 16, label: 'High School', key: 'highSchool' });
  return gradesToReturn;
};

export const renderFTE = (fulltime: number): string => {
  switch (fulltime) {
    case 0.5:
      return 'Part time';
    case 0.75:
      return 'Full and part time';
    default:
      return 'Full time';
  }
};
