import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { nimbleTheme } from 'theme';

export const indianaTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#0071B0',
      dark: '#00517E',
    },
  },
});

export const missouriTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#004bbd',
      light: '#026BC7',
      dark: '#003665',
    },
  },
});

export const tennesseeTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#030E77',
      light: '#3643C5',
      dark: '#090E42',
    },
  },
});

export const StateThemes = {
  IN: indianaTheme,
  MO: missouriTheme,
  TN: tennesseeTheme,
};

export const getTheme = (stateCode: string | null = null) => {
  if (stateCode && StateThemes[stateCode]) {
    return createTheme(deepmerge(nimbleTheme, StateThemes[stateCode]));
  }
  return nimbleTheme;
};
