import styled from 'styled-components';

export default function RadioButton({ checked, value, label, id, onClick, name = 'radio-group' }) {
  return (
    <>
      <StyledRadioButton
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={checked}
        onClick={onClick}
        data-testid={id}
      />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </>
  );
}

const StyledLabel = styled.label`
  font-weight: normal;
  font-size: 19px;
  display: inline-block;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledRadioButton = styled.input`
  box-sizing: border-box;

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 24px;
    height: 24px;
    border: 2px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #00b88d;
    position: absolute;
    top: 4px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  &:checked + label:before {
    border-color: #00b88d;
  }
`;
