import tndoeStateLogo from 'assets/state_logos/tn-doe-logo.png';
import missouriDefaultMap from 'assets/connect/connect-default-map.png';
import tennesseeDefaultMap from 'assets/connect/connect-tennessee-default-map2.png';
import indianaDefaultMap from 'assets/connect/connect-indiana-default-map.png';

export const nimbleConnectUrlFlag = 'connect';

export const CAROUSEL_SLIDE_TYPES = {
  IMAGE_WITH_TEXT: 'image_with_text',
  QUOTE: 'quote',
};

export const CONNECT_JOBBOARD_STATE_CODES = {
  INDIANA_STATE_CODE: 'in',
  MISSOURI_STATE_CODE: 'mo',
  TENNESSEE_STATE_CODE: 'tn',
};

export const CONNECT_JOBBOARD_STATES = {
  IN: CONNECT_JOBBOARD_STATE_CODES.INDIANA_STATE_CODE,
  MO: CONNECT_JOBBOARD_STATE_CODES.MISSOURI_STATE_CODE,
  TN: CONNECT_JOBBOARD_STATE_CODES.TENNESSEE_STATE_CODE,
};

export const CONNECT_JOBBOARD_STATE_LOGOS = {
  TN: tndoeStateLogo,
};

export const CONNECT_JOBBOARD_DEFAULT_MAP_IMAGES = {
  IN: indianaDefaultMap,
  MO: missouriDefaultMap,
  TN: tennesseeDefaultMap,
};

export const CONNECT_JOBBOARD_STATE_LABELS = {
  IN: 'Indiana',
  MO: 'Missouri',
  TN: 'Tennessee',
};
