import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { JobsDiscoveryValuePropBanner } from './JobDiscoveryValuePropBanner';
import { Aeonik, getStateLabel } from './utils';
import { JobDiscoveryHeaderProps } from './types';
import { useStateCode } from '../ConnectStateCodeContextProvider';

export const Header: React.FC<JobDiscoveryHeaderProps> = ({ isUserAuthenticated }) => {
  const stateCode = useStateCode();
  const showAuthenticatedValuePropsBanner = true;

  return (
    <HeaderContainer>
      {isUserAuthenticated && !showAuthenticatedValuePropsBanner ? (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Title>{`Find a teaching job you’ll love in ${getStateLabel(stateCode)}`}</Title>
            <SubTitle>
              Nimble Connect compiles all teaching jobs statewide and helps you compare key
              information to find the best fit. Skip the tedious online applications and connect
              directly with principals hiring in your area. Or mark your profile as “open to
              contact”, and let the schools come to you!
            </SubTitle>
          </Grid>
          <Grid item>
            <div></div>
          </Grid>
        </Grid>
      ) : (
        <JobsDiscoveryValuePropBanner isUserAuthenticated={isUserAuthenticated} />
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  alignSelf: 'stretch',
  padding: '0px 0px 20px',
  borderBottom: '1px solid #EAECF0',
  marginBottom: '32px',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 0px 20px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: Aeonik,
  color: '#101828',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '38px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '30px',
  },
}));

const SubTitle = styled(Typography)(() => ({
  color: '#475467',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
}));
